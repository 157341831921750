import React from 'react';


import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const UniversityHeader = ({ fetchUnassignedUniversities, fetchUniversities, isUnassignedView, setIsUnassignedView }) => {
    const handleBack = () => {
        setIsUnassignedView(false); // Disable Unassigned View
        fetchUniversities(); // Call the function to fetch regular universities
    };

    return (
        <div>
            <div className='flex gap-6'>
                {!isUnassignedView ? (
                    <>
                        <div>
                            <button className="bg-black text-white text-[12px] px-4 py-2 rounded pt-sans-bold" onClick={fetchUnassignedUniversities}>
                                Unassigned Universities
                            </button>
                        </div>
                        <div>
                            <button className="border border-1 border-black text-[12px]  text-black px-4 py-2 rounded pt-sans-bold ">
                                Associates
                            </button>

                        </div>
                    </>
                ) : (
                    <div className='flex  gap-4'>
                    <div>
                        <button className="bg-black text-white text-[12px] px-4 py-2 rounded pt-sans-bold" onClick={ handleBack}>
                                <ArrowBackIcon style={{ fontSize: '15px', fontWeight: 'bold' }} />
                                All Universities
                            </button>
                    </div>
                      <div>
                      <button className="border border-1 border-black text-[12px]  text-black px-4 py-2 rounded pt-sans-bold ">
                         Associates
                      </button>
  
                  </div>
                  </div>
                )}
            </div>
        </div>
    );
};

export default UniversityHeader;
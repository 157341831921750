

import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosInstance/axiosInstance';
import { motion } from 'framer-motion';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const AddStaffForm = ({ setIsAddStaffVisible, fetchData }) => {
    const [staffData, setStaffData] = useState({
        first_name: '',
        phone: '',
        email: '',
        stages
            : [],
        designation: '',

        is_staff: true,
    });
    const [isOpen, setIsOpen] = useState(false);
    const [stageDropdownOpen, setStageDropdownOpen] = useState(false);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [stageError, setStageError] = useState('');

    const [options, setOptions] = useState({
        stages: [],
        designations: []
    });

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleStageToggle = () => {
        setStageDropdownOpen(!stageDropdownOpen);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!staffData.first_name || !staffData.first_name.trim()) {
            newErrors.first_name = 'Name is required';
        }
        if (!staffData.phone || !staffData.phone.trim()) {
            newErrors.phone = 'Phone Number is required';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleStaffSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setIsLoading(true);
            try {
                const response = await axiosInstance.post('/account/api/v1/register-agency-staff/', staffData);
                if (response.status === 201) {
                    fetchData();
                    setIsAddStaffVisible(false);

                    setStaffData({
                        first_name: '',
                        phone: '',
                        email: '',
                        stages: [],
                        designation: '',
                    });

                    setIsSuccess(true);
                }
            } catch (error) {
                console.error('Error submitting form:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const stagesResponse = await axiosInstance.get('kanban/api/v1/stage_dropdown/?limit=&search=');
                const designationsResponse = await axiosInstance.get('/kanban/api/v1/designation_dropdown/');

                setOptions({
                    stages: stagesResponse.data,
                    designations: designationsResponse.data,
                });
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        fetchOptions();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStaffData(prevUserData => ({
            ...prevUserData,
            [name]: value,
        }));


        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: '',
        }));
    };



    const handleSelect = (value) => {
        setStaffData(prevUserData => ({
            ...prevUserData,
            designation: value,
        }));
        setIsOpen(false);
    };

    const handleStageSelect = (value) => {
        if (staffData.stages.length < 3) {
            setStaffData(prevUserData => ({
                ...prevUserData,
                stages: [...prevUserData.stages, value],
            }));
            setStageError('');
        } else {
            setStageError('Maximum 3 stages can be selected');
        }
        setStageDropdownOpen(false);
    };



    const handleTagRemove = (value) => {
        setStaffData(prevUserData => ({
            ...prevUserData,
            stages: prevUserData.stages.filter(stage => stage !== value),
        }));
    };

    const handleClose = () => {
        setIsAddStaffVisible(false);
    };

    return (
        <div>
            <motion.div
                className="fixed inset-0 bg-black bg-opacity-70"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            />
            <motion.div
                className="p-6   z-10"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
            >
                <div className="flex justify-center items-center py-10 fixed inset-0 z-50">
                    <div>
                        <div className="bg-white shadow-lg rounded-md">
                            <div className="bg-black">
                                <span className="text-2xl font-bold px-6 py-6 text-white flex justify-center items-center">
                                    Add Staff
                                </span>
                            </div>
                            <div className="px-6 pt-8">
                                <div className="flex gap-2 flex-col">
                                    <div className="flex gap-4 justify-between flex-col md:flex-row">
                                        <div className="space-y-2">
                                            <label
                                                htmlFor="first_name"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                First Name
                                            </label>
                                            <input
                                                type="text"
                                                id="first_name"
                                                name="first_name"
                                                value={staffData.first_name}
                                                onChange={handleChange}
                                                placeholder='name'
                                               
                                                className="border-none w-[270px] pt-sans-regular  outline-none text-sm rounded-md p-2 bg-gray-100"
                                            />
                                            {errors.first_name && <p className="text-red-500 text-xs">{errors.first_name}</p>}
                                        </div>
                                        <div className="space-y-2">
                                            <label
                                                htmlFor="phone"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Phone Number
                                            </label>
                                            <input
                                                type="tel"
                                                id="phone"
                                                name="phone"
                                                onChange={handleChange}
                                                value={staffData.phone}
                                                placeholder='number'
                                                
                                                className="border-none w-[270px]  pt-sans-regular border-blue-50 outline-none text-sm rounded-md p-2 bg-gray-100"
                                            />
                                            {errors.phone && <p className="text-red-500 text-xs">{errors.phone}</p>}
                                        </div>
                                    </div>
                                    <div className="flex justify-between flex-col md:flex-row">
                                        <div className="space-y-2">
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={staffData.email}
                                                onChange={handleChange}
                                                placeholder='email'
                                               
                                                className="border-none w-[270px] pt-sans-regular  outline-none text-sm rounded-md p-2 bg-gray-100"
                                            />
                                        </div>
                                        <div className="space-y-2 relative">
                                            <label
                                                htmlFor="designation"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Designation
                                            </label>
                                            <div
                                                className="border-none w-[270px]  pt-sans-regular outline-none text-sm rounded-md p-2 text-gray-400 bg-gray-100 cursor-pointer"
                                                onClick={handleToggle}
                                            >
                                                {staffData.designation ? options.designations.find(opt => opt.id === staffData.designation)?.name : 'Enter Designation'}
                                            </div>
                                            {isOpen && (
                                                <ul className="absolute z-10 w-full bg-white border border-gray-200 rounded-md mt-1 max-h-32 overflow-auto">
                                                    {options.designations.map(option => (
                                                        <li
                                                            key={option.id}
                                                            onClick={() => handleSelect(option.id)}
                                                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer font-medium text-[12px]"
                                                        >
                                                            {option.name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                            {errors.designation && <p className="text-red-500 text-xs">{errors.designation}</p>}
                                        </div>
                                    </div>
                                    <div className="flex justify-between">
                                        <div className="space-y-2 relative">
                                            <label
                                                htmlFor="stage"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Stage
                                            </label>
                                            <div
                                                className="border-none w-[270px] border-blue-50 pt-sans-regular text-sm rounded-md p-2 bg-gray-100 text-gray-400 cursor-pointer"
                                                onClick={handleStageToggle}
                                            >
                                                {staffData.stages
                                                    .length > 0 ? 'Selected Stages' : 'Enter Stage'}
                                            </div>
                                            {stageDropdownOpen && (
                                                <ul className="absolute z-10 w-full bg-white border border-gray-200 rounded-md mt-1 max-h-32 overflow-auto">
                                                    {options.stages.map(option => (
                                                        <li
                                                            key={option.id}
                                                            onClick={() => handleStageSelect(option.id)}
                                                            className="px-4 py-2 hover:bg-gray-100 text-[12px] cursor-pointer font-medium "
                                                        >
                                                            <div className='flex gap-2 items-center'>
                                                                <div style={{
                                                                    backgroundColor: `#${option.
                                                                        bg_color
                                                                        }`,
                                                                }} className='w-2 h-2 rounded-full'></div>
                                                                <div className='text-[12px]'>{option.name}</div>

                                                            </div>


                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                            {errors.stage && <p className="text-red-500 text-xs">{errors.stage}</p>}
                                            {stageError && <p className="text-red-500 text-xs">{stageError}</p>}
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap gap-2 mt-2">
                                        {staffData.stages.map((stageId, index) => {
                                            const stage = options.stages.find(stage => stage.id === stageId);
                                            return (
                                                <div className='flex justify-between '>
                                                    <div
                                                        key={index}
                                                        className="flex items-center px-3 py-1 rounded-md text-sm w-32 font-medium"
                                                        style={{ backgroundColor: `#${stage?.bg_color}`, color: `#${stage?.color_code}` }}
                                                    >
                                                        {stage?.name}




                                                    </div>
                                                    <div

                                                        onClick={() => handleTagRemove(stageId)}
                                                        className="ml-2 text-red-500  "
                                                    >
                                                        <DeleteForeverIcon style={{ color: 'red' }} />
                                                    </div>


                                                </div>

                                            );
                                        })}
                                    </div>

                                </div>
                                <div className="flex justify-center md:justify-end py-6 pt-20 gap-4">
                                    <button
                                        onClick={handleStaffSubmit}
                                        type="submit"
                                        className="bg-black pt-sans-regular text-[14px]  text-white font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline"
                                    > {isLoading && (
                                        <svg
                                            className="animate-spin h-5 w-5 mr-3 border-t-2 border-b-2 border-white"
                                            viewBox="0 0 24 24"
                                        ></svg>
                                    )}
                                        {isLoading ? 'Submitting...' : 'Submit'}
                                    </button>
                                    <button
                                        type="button"
                                        onClick={handleClose}
                                        className="bg-black pt-sans-regular text-[14px]  text-white font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline"
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>
    );
}

export default AddStaffForm;

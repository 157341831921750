import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosInstance/axiosInstance';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import { useNavigate } from 'react-router-dom';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

const logout = async (navigate) => {
    const refreshToken = localStorage.getItem('refreshToken');

    if (!refreshToken) {
        console.error('No refresh token found');
        return;
    }

    try {
        const response = await axiosInstance.post('/account/api/logout/', {
            refresh: refreshToken,
        });

        if (response.status === 200) {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            console.log('Logged out successfully');
            navigate('/login');
        } else {
            console.error('Failed to logout:', response.status, response.data);
        }
    } catch (error) {
        if (error.response && error.response.status === 400) {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            navigate('/login');
        } else {
            console.error('Error during logout:', error);
        }
    }
};

const Logout = () => {
    const navigate = useNavigate();
    const [menus, setMenu] = useState(false);
    const [error, setError] = useState(null);

    // Fetching menu icons on component mount
    useEffect(() => {
        const fetchIcons = async () => {
            try {
                const response = await axiosInstance.get('account/api/v1/module_menus/');
                setMenu(response.data);
            } catch (error) {
                console.error('Error fetching icons:', error);
                setError(error);
            }
        };

        fetchIcons();
    }, []);

    const handleLogout = () => {
        logout(navigate);
    };

    const handleProfile = () => {
        navigate('/profile');
    };

    return (
        <div className='flex gap-2 flex-col '>
            <div className='flex gap-2'>
                <div>
                    <LogoutTwoToneIcon style={{ fontSize: '20px' }} />
                </div>
                <div>
                    <button className='text-[11px] inter-font' onClick={handleLogout}>
                        Logout
                    </button>
                </div>
            </div>

            {menus && menus.add_staff && (
                <div className='flex gap-2 items-center '>
                    <div>
                        <AccountBoxIcon style={{ fontSize: '20px' }} />
                    </div>
                    <div>
                        <button className='text-[11px] inter-font' onClick={handleProfile}>
                            Profile
                        </button>
                    </div>
                </div>
            )}

            {error && <div>Error loading menus</div>}
        </div>
    );
};

export default Logout;








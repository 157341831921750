import React, { useState } from 'react';
import axiosInstance from '../axiosInstance/axiosInstance';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const AgencyDescription = () => {
    const [formData, setFormData] = useState({
        description: '',
        profileImgUrl: null,
        location: '', 
    });
    const [alert, setAlert] = useState(null); // New state for alert
    const showAlert = (type, message) => {
        setAlert({ type, message });
        setTimeout(() => setAlert(null), 2000); // Clear alert after 2 seconds
    };

    const uploadImage = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axiosInstance.post('home/api/v1/upload-file/', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            return response.data.image_url;
        } catch (error) {
            console.error('Error uploading image:', error);
            showAlert('error', 'Failed to upload image');
            return null;
        }
    };

    const handleLogoChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const uploadedUrl = await uploadImage(file);
            setFormData((prev) => ({
                ...prev,
                logo: file,
                profileImgUrl: uploadedUrl,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            about: formData.description,
            profile_img: formData.profileImgUrl,
            location: formData.location, 
        };

        try {
            const response = await axiosInstance.post('account/api/v1/agency-profile-update/', payload);
            if (response.status === 200) {
                setFormData({
                    description: '',
                    profileImgUrl: null,
                    location: '', 
                });
                showAlert('success', 'Agency details updated successfully');
            } else {
                showAlert('error', 'Failed to update agency details');
            }
        } catch (error) {
            console.error('Error updating agency details:', error);
            showAlert('error', 'Error updating agency details');
        }
    };

    return (
        <div className="w-1/2">
            <div className="bg-black flex items-center justify-center">
                <span className="text-2xl font-bold px-6 py-4 text-white">
                    Agency Details
                </span>
            </div>
            <div className="bg-white shadow-lg px-10 py-10 rounded-md">
              
                <form onSubmit={handleSubmit}>
                <div className="flex flex-col gap-2 mb-4"> {/* New location input */}
                        <label className="inter-light text-[12px]" htmlFor="location">Location:</label>
                        <input
                            type="text"
                            id="location"
                            name="location"
                            value={formData.location}
                            onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                            placeholder="Enter agency location"
                            required
                            className="w-full p-2 inter-light text-[12px] border border-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        />
                    </div>
                    <div className="flex flex-col gap-2 mb-4">
                        <label className="inter-light text-[12px]" htmlFor="description">Description:</label>
                        <textarea
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                            placeholder="Enter agency description"
                            required
                            rows={4}
                            className="w-full p-2 inter-light resize-none text-[12px] border border-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label htmlFor="logo" className="block inter-light text-[12px]">Agency Logo:</label>
                        <div className="flex items-center justify-center w-full">
                            <label
                                htmlFor="logo"
                                className="flex flex-col items-center justify-center w-full h-32 border-2 border-dashed border-gray-500 rounded-lg cursor-pointer hover:border-black relative"
                            >
                                {formData.profileImgUrl ? (
                                    <img
                                        src={formData.profileImgUrl}
                                        alt="Uploaded logo preview"
                                        className="h-full w-full object-contain rounded-md px-2 py-2"
                                    />
                                ) : (
                                    <>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-10 h-10 text-gray-400"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm10 7a1 1 0 00-1-1H7a1 1 0 100 2h6a1 1 0 001-1z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <span className="mt-2 inter-light text-[12px] text-gray-500">
                                            Click to upload logo
                                        </span>
                                    </>
                                )}
                                <input
                                    id="logo"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleLogoChange}
                                    className="hidden"
                                />
                            </label>
                        </div>
                    </div>
                    <div className="flex justify-end  pt-4">
                        <button
                            onClick={handleSubmit}
                            className="bg-black text-white font-bold py-1 px-6 text-[13px] rounded-sm inter-light"
                        >
                            Save
                        </button>
                    </div>
                </form>
                <div className='py-1'>
                {alert && (
                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Alert severity={alert.type}>{alert.message}</Alert>
                    </Stack>
                )}

                </div>
                
            </div>
        </div>
    );
};

export default AgencyDescription;



import React from 'react'
import SideBar from '../SideBar/SideBar'
import UniversityCourse from './UniversityCourse'
import { useLocation ,useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const UniversityCourseDashboard = () => {
    const location = useLocation();
    const { state } = location;
    const universityDetails = state?.universityDetails;
    const universityId = state?.universityId;  
    const navigate = useNavigate(); 
    const handleBackClick = () => {
        navigate('/university'); 
    };
    return (
        <div>
            <div className='flex bg-gray-100'>
                <div className='pt-10 bg-white  '>
                    <SideBar />
                </div>
                <div className='bg-gray-100 w-full overflow-x-hidden'>
                    <div className=' flex px-8 pt-4 gap-4'>
                    <button className="bg-black text-white text-[12px] px-4 py-1 rounded pt-sans-bold "  onClick={handleBackClick}>
                     <ArrowBackIcon style={{fontSize:'15px' ,fontWeight:'bold'}}/>
                    </button> 
                    <div className='flex gap-6'>
                <div>
                    <button className="bg-black text-white text-[12px] px-4 py-2 rounded pt-sans-bold ">
                        Unassigned Universities
                    </button>

                </div>
                <div>
                    <button className="border border-1 border-black text-[12px]  text-black px-4 py-2 rounded pt-sans-bold ">
                       Associates
                    </button>

                </div>
            </div>
                   
                    </div>
                    <div className='px-4 py-4'>
                    <div className='flex flex-col bg-white shadow-lg   '>
                        <UniversityCourse universityDetails={universityDetails} universityId={universityId} />
                    </div>

                    </div>
                    

                </div>



            </div>





        </div>
    )
}

export default UniversityCourseDashboard
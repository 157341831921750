
import React, { useState, useEffect, useRef } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import axiosInstance from '../axiosInstance/axiosInstance';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AnimatePresence } from 'framer-motion';
import Logout from '../Login/Logout';
import { useLocation } from 'react-router-dom';
import AddStudent from '../Forms/AddStudent';

const Header = ({ isOpenStage, fetchClients, setIsOpenStage, handleSearchInputChange, handleAgencySelection, handleStageSelection, selectedAgency, isOpen, setIsOpen, selectedStage, searchQuery, menus, handleCancelall }) => {
    const [isAddClientVisible, setIsAddClientVisible] = useState(false);
    const [agencies, setAgencies] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [stages, setStages] = useState([]);
    const [showClearIcon, setShowClearIcon] = useState(false);
    const [showStageIcon, setShowStageIcon] = useState(false);
    const [profileOpen, setProfileOpen] = useState(false)
    const [totalClients, setTotalClients] = useState(0);

    useEffect(() => {
        fetchAgencies();
        fetchStages();
    }, []);



    const dropdownAgencyRef = useRef(null);
    const dropdownStageRef = useRef(null);
    const dropdownProfileRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropdownAgencyRef.current && !dropdownAgencyRef.current.contains(event.target)) {
            setIsOpen(false);
        }
        if (dropdownStageRef.current && !dropdownStageRef.current.contains(event.target)) {
            setIsOpenStage(false);
        }
        if (dropdownProfileRef.current && !dropdownProfileRef.current.contains(event.target)) {
            setProfileOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const userInfoString = localStorage.getItem('user_info');
    const userInfo = JSON.parse(userInfoString);

    const fetchAgencies = async () => {
        try {
            const response = await axiosInstance.get('kanban/api/v1/agency_dropdown/?limit=&search=');
            setAgencies(response.data);

            setTotalClients(response.data.length > 0 ? response.data[0].total_clients : 0);
            setLoading(false);

        } catch (error) {
            console.error('Error fetching agencies:', error);
            setError(error);
            setLoading(false);
        }
    };

    const fetchStages = async () => {
        try {
            const response = await axiosInstance.get('kanban/api/v1/stage_dropdown/?limit=&search=');
            setStages(response.data);

            setLoading(false);
        } catch (error) {
            console.error('Error fetching stages:', error);
            setError(error);
            setLoading(false);
        }
    };





    const toggleDropdown = () => {

        setIsOpen(!isOpen);
        setIsOpenStage(false);
        setProfileOpen(false)
    };

    const toggleDropdowStage = () => {
        setIsOpenStage(!isOpenStage);
        setIsOpen(false);
        setProfileOpen(false)
    };
    const toggleDropdownProfile = () => {
        setProfileOpen(!profileOpen)
        setIsOpen(false);
        setIsOpenStage(false);
        console.log("helo click" );
        


    }

    const toggleAddClientVisibility = () => {
        setIsAddClientVisible(!isAddClientVisible);
    };

    const handleClose = () => {
        setIsAddClientVisible(false);
    };


    const handleClearStageSelection = () => {
        if (isOpen) {
            handleStageSelection(null);

        }
        handleStageSelection(null);

        setShowStageIcon(false);
        setIsOpen(false)

    };

    useEffect(() => {

        if (selectedAgency) {
            setShowClearIcon(true);
        } else {
            setShowClearIcon(false);
        }
    }, [selectedAgency]);
    useEffect(() => {

        if (selectedStage) {
            setShowStageIcon(true);
        } else {
            setShowStageIcon(false);
        }
    }, [selectedStage]);

    const location = useLocation();
    const showSearch = location.pathname !== '/userside';
    const showstaff = location.pathname !== '/agentstaffside';
    const showSource = location.pathname !== '/SourceDashboard';
    const hideSerach = location.pathname !== '/countrydashboard';
    const hideSearchBar = location.pathname !== '/universitydashboard';
    const hideCourse = location.pathname !== '/courseDashboard';



    return (
        <div className=''>
            <div className="flex flex-row justify-between items-center px-4 pb-6 pt-4">
                <div className='flex gap-12'>
                    <div className="mb-4 md:mb-0">
                        <div className='flex gap-10 items-center justify-center cursor-pointer' onClick={handleCancelall}>
                            <div className='w-6 h-6 ml-2 overflow-hidden flex justify-center items-center'>
                                <img className='w-full h-full object-cover' src="./Group.png" alt="logo" />
                            </div>
                            <div>
                                <span className="poppins-extrabold text-2xl xl:text-3xl font-bold">Dashboard</span>
                            </div>
                        </div>
                    </div>
                    {showSearch && showstaff && showSource && hideSerach && hideSearchBar && hideCourse && (
                        <div className="flex flex-wrap items-center gap-2">
                            <div className="relative">
                                <span className="absolute inset-y-0 left-0 pl-3 text-green-400 text-sm flex items-center">
                                    <SearchIcon />
                                </span>
                                <input
                                    type="text"
                                    className="inter-font block w-96 text-[12px] rounded-md pr-12 pl-10 pr-3 py-2.5 bg-gray-100 border-gray-200 border-none placeholder-gray-400 focus:outline-none tracking-wide"
                                    placeholder={searchQuery ? searchQuery : "Search here... "}
                                    onChange={handleSearchInputChange}
                                />
                            </div>
                            <button
                                type="button"
                                onClick={toggleAddClientVisibility}
                                className="inter-font bg-black text-white text-sm font-bold px-4 py-2 rounded-md hover:bg-gray-900 focus:outline-none focus:bg-gray-900"
                            >
                                Add Client
                            </button>
                        </div>
                    )}

                </div>
                <div className="flex items-center justify-center relative" ref={dropdownProfileRef}>
                    <div className="flex flex-col items-center">
                        <span className="inter-font text-[12px] font-semibold text-black opacity-80">
                            {userInfo && userInfo.first_name ? userInfo.first_name : userInfo && userInfo.username}
                        </span>
                        <span className="inter-font text-[11px] font-semibold text-gray-400">
                            Admin
                        </span>
                    </div>
                    <div className='flex items-center cursor-pointer ' onClick={toggleDropdownProfile}>
                        <div className="w-10 h-10 ml-2">
                            <img className="w-full h-full rounded-full" src="/profile.jpg" alt="Profile" />
                        </div>
                        <div>
                            <KeyboardArrowDownIcon style={{ fontSize: '20px', color: 'gray' }} />
                        </div>
                    </div>

                    <div className='absolute  top-10'>
                        {
                            profileOpen && (
                                <div className='bg-white rounded-md shadow-lg px-8 py-2'>
                                    <Logout />

                                </div>
                            )

                        }
                    </div>

                </div>
            </div>
            <hr></hr>
            <div className='absolute z-10 right-10 top-0 left-10'>
                <AnimatePresence>
                    {/* {isAddClientVisible && <AddClients menus={menus} onClose={handleClose} setIsAddClientVisible={setIsAddClientVisible} />} */}
                    {isAddClientVisible && <AddStudent menus={menus} onClose={handleClose} setIsAddClientVisible={setIsAddClientVisible} fetchClients={fetchClients} />}
                </AnimatePresence>
            </div>
        </div>
    );
};

export default Header;


import React, { useState, useEffect } from 'react'
import SideBar from '../SideBar/SideBar'
import UniversiyAgentSearch from './UniversityAgentSearch';

import axiosInstance from '../axiosInstance/axiosInstance'
import { useParams,useNavigate,useLocation  } from 'react-router-dom'; 

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import CoursePage from './CoursePage'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
const CourseAgentDashboard = () => {

   
    const [courses, setCourses] = useState([]); 
    const [isAddCountryVisible, setIsAddCountryVisible] = useState(false);
    const [isUpdateCountryVisible, setIsUpdateCountryVisible] = useState(false);
    const [isAddUniversityVisible, setIsAddUniversityVisible] = useState(false);
    const [isUpdateUniversityVisible, setIsUpdateUniversityVisible] = useState(false);
    const [universities, setUniversities] = useState([]);
    const [nextPage, setNextPage] = useState(null);
    const [prevPage, setPrevPage] = useState(null);
    const [searchCountry, setSearchCountry] = useState('');
    const [searchUniversity, setSearchUniversity] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [isMyUniversity, setIsMyUniversity] = useState(false);
    const [isBackButtonVisible, setIsBackButtonVisible] = useState(false);
    const [agencyUniversities, setAgencyUniversities] = useState([]);
    const [myUniversityCount, setMyUniversityCount] = useState(0); // State to store "My Universities" count
    const [totalUniversityCount, setTotalUniversityCount] = useState(0);
    
    
    
    
    // This ensures courses is an array by default

    const { universityId } = useParams();
    const navigate = useNavigate(); 
    const location = useLocation();
    useEffect(() => {
        const pattern = new RegExp(`^/AgentCourse/\\d+$`); // Adjust regex if your ID format changes
        if (pattern.test(location.pathname)) {
            setIsBackButtonVisible(true); // Show back button if the pattern matches
        } else {
            setIsBackButtonVisible(false); // Hide back button otherwise
        }
    }, [location.pathname]);
  
    const fetchCourses = (url = 'home/api/v1/courses/', country = '', university = '', universityId = null) => {
        axiosInstance.get(url, {
            params: {
                country: country,
                search_university: university,
                university: universityId, // Pass universityId if available
                limit: 4, // Limit the number of courses per page
            },
        })
        .then(response => {
            setCourses(response.data.results);
            setNextPage(response.data.next);
            setPrevPage(response.data.previous);
        })
        .catch(error => {
            console.error('Error fetching courses:', error);
        });
    };
    useEffect(() => {
        if (universityId) {
            fetchCourses('home/api/v1/courses/', searchCountry, searchUniversity, universityId); // Pass search filters to API
        }
    }, [universityId, searchCountry, searchUniversity]);
    
    




    const handlePrevious = () => {
        if (prevPage) {
            fetchCourses(prevPage);
            setCurrentPage((prev) => prev - 1);
        }
    };
    const handleNext = () => {
        if (nextPage) {
            fetchCourses(nextPage);
            setCurrentPage((prev) => prev + 1);
        }
    };
   
    const fetchAgencyUniversities = async () => {
        try {
            const response = await axiosInstance.get('home/api/v1/agency_universities/');
            const agencyData = Array.isArray(response.data) ? response.data : [];
            setAgencyUniversities(agencyData);
            setMyUniversityCount(agencyData.length); // Set "My Universities" count based on data length
        } catch (error) {
            console.error('Error fetching agency universities:', error);
        }
    };

    const toggleAddCountryModal = () => {
        setIsAddCountryVisible(prev => !prev);
    };
    const toggleAddUniversityModal = () => {
        setIsAddUniversityVisible(prev => !prev);
    };
    const fetchUniversities = (url = 'home/api/v1/universities/?limit=4', country = '', university = '') => {
        axiosInstance.get(url, {
            params: {
                country: country,
                search_university: university,
            },
        })
            .then(response => {
                setUniversities(response.data.results);
              
                setTotalUniversityCount(response.data.count);
            })
            .catch(error => {
                console.error("There was an error fetching the university data!", error);
            });
    };


    useEffect(() => {
        fetchUniversities('home/api/v1/universities/?limit=4', searchCountry, searchUniversity);
    }, [searchCountry, searchUniversity]);


    
    const fetchMyUniversities = (country = '', university = '') => {
        const myUniversityUrl = 'home/api/v1/agency_universities/?limit=4';

        axiosInstance.get(myUniversityUrl, {
            params: {
                country,
                search_university: searchUniversity,
            },
        })
            .then(response => {
                setUniversities(response.data.results);
                
              
                setIsMyUniversity(true);
                setIsBackButtonVisible(true);
                // Set this to true to indicate "My Universities" is active
            })
            .catch(error => {
                console.error("There was an error fetching the my university data!", error);
            });
    };
    useEffect(() => {
        // Fetch universities based on searchCountry or searchUniversity when the component mounts or updates
        if (!isMyUniversity) {
            fetchUniversities('', searchCountry, searchUniversity);
        }
    }, [searchCountry, searchUniversity, isMyUniversity]);


    const handleMyUniversitiesClick = () => {
        setIsMyUniversity(true);  // Mark "My Universities" as active
        setCurrentPage(1);  // Reset pagination
        fetchMyUniversities(); // Call the separate API for "My Universities"
    };
    const handleBackButtonClick = () => {
        
        navigate('/UniversityAgent'); // Navigate back to the UniversityAgent page
    };
    useEffect(() => {
        // Fetch universities based on searchCountry or searchUniversity when the component mounts or updates
        if (!isMyUniversity) {
            fetchUniversities('', searchCountry, searchUniversity);
        }
    }, [searchCountry, searchUniversity, isMyUniversity]);

    useEffect(() => {
        fetchAgencyUniversities(); // Call to fetch "My Universities" when the component mounts
    }, []); // Runs only on mount




    return (
        <div>
            <div className='flex bg-gray-50 h-full'>
                <div className='pt-10 bg-white h-full sticky top-0 '>
                    <SideBar />
                </div>
                <div className='flex flex-col px-4  overflow-y-auto  w-full '>
                <div className=' flex px-8 py-4 gap-4'>
                    {isBackButtonVisible && (
                            <button className="bg-black text-white text-[12px] px-4 py-1 rounded pt-sans-bold"   onClick={handleBackButtonClick}>
                                <ArrowBackIcon style={{ fontSize: '15px', fontWeight: 'bold' }} />
                            </button>
                        )}
                        <button className="border border-black  text-[12px] px-4 py-2 rounded pt-sans-bold " onClick={handleMyUniversitiesClick}>
                        My Universities {myUniversityCount}/{totalUniversityCount}
                        </button>

                    </div>


                    <div className='shadow-md bg-white flex flex-col gap-10 pt-10 px-4 h-full'>
                    <UniversiyAgentSearch toggleAddCountryModal={toggleAddCountryModal} toggleAddUniversityModal={toggleAddUniversityModal} onSearchCountry={setSearchCountry}
                            onSearchUniversity={setSearchUniversity} searchUniversity={searchUniversity} searchCountry={searchCountry} />

                        <CoursePage courses={courses}  />
                    </div>
                    <div className="flex gap-2 py-6 items-center  justify-center ">

                        <button
                            className=" border border-gray-600  text-gray-700  rounded-sm pt-sans-bold"
                            onClick={handlePrevious}
                        >
                            <KeyboardDoubleArrowLeftIcon style={{ fontSize: '20px' }} />
                        </button>
                        <button
                            className=" border border-gray-600  text-gray-700  rounded-sm pt-sans-bold"
                            onClick={handlePrevious}
                        >
                            <KeyboardArrowLeftIcon style={{ fontSize: '20px' }} />
                        </button>

                        <div className=" text-[14px] bg-black px-4 py-0.5 text-white pt-sans-bold">{currentPage}</div>
                        {nextPage && (
                            <div>
                                <button
                                    className="border border-gray-600 text-gray-700 rounded-sm pt-sans-bold"
                                    onClick={handleNext}
                                >
                                      < KeyboardArrowRightIcon style={{ fontSize: '20px' }}/>

                                </button>
                            </div>
                        )}
                        {nextPage && (
                            <div>
                                <button
                                    className="border border-gray-600 text-gray-700 rounded-sm pt-sans-bold"
                                    onClick={handleNext}
                                >
                                    <KeyboardDoubleArrowRightIcon style={{ fontSize: '20px' }} />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CourseAgentDashboard
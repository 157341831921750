

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { ReactComponent as CountrySVG } from '../../Assets/country.svg';
import { ReactComponent as SettingsSVG } from '../../Assets/settings.svg';
import { ReactComponent as CourseSVG } from '../../Assets/course.svg';
import { ReactComponent as ClientsSVG } from '../../Assets/clients.svg';
import { ReactComponent as DashboardSVG } from '../../Assets/dashboard.svg';
import { ReactComponent as CourseagentSVG } from '../../Assets/courseagent.svg';
import GroupPNG from '../../Assets/Group.png'; // Correct way to import PNG

import axiosInstance from '../axiosInstance/axiosInstance';

const SideBar = ({ toggleUserSideVisibility, toggleAgentSideVisibility }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const userFromLocalStorage = JSON.parse(localStorage.getItem('user_info'));
  const isUserSideVisible = localStorage.getItem('isUserSideVisible') === 'true';
  const [menus, setMenu] = useState(false);
  const [error, setError] = useState(null);

  const handleIconClick = () => {
    navigate("/userside");
  };

  const handleIconBack = () => {
    navigate("/dashboard");
  };

  const handleAgentStaff = () => {
    navigate("/agentstaffside");
  };

  const handleSource = () => {
    navigate("/sourceDashboard");
  };

  const handleCourse = () => {
    navigate("/university");
  };

  const handleCountry = () => {
    navigate("/countrydashboard");
  };

  const handleAgent = () => {
    navigate("/UniversityAgent");
  };

  useEffect(() => {
    const fetchIcons = async () => {
      try {
        const response = await axiosInstance.get('account/api/v1/module_menus/');
        setMenu(response.data);
      } catch (error) {
        console.error('Error fetching icons:', error);
        setError(error);
      }
    };

    fetchIcons();
  }, []);

  // Check for AgentCourse dynamic route using startsWith
  const isAgentCoursePath = location.pathname.startsWith('/AgentCourse');

  return (
    <div className="h-full relative " style={{ height: '95vh' }}>

      {(location.pathname === '/university' ||
        location.pathname === '/UniversityCourse' ||
        location.pathname === '/countrydashboard' ||
        location.pathname === '/UniversityAgent' ||
        isAgentCoursePath) && (
          <div className=''>
            <div className='flex items-center gap-2 pb-6 justify-center '>
              <div className='w-6 h-6 ml-2 overflow-hidden flex justify-center items-center'>
                {/* <img className='w-full h-full object-cover' src="./Group.png" alt="logo" /> */}
                <div className='w-6 h-6  overflow-hidden flex justify-center items-center'>
                  <img className='w-full h-full object-cover' src={GroupPNG} alt="logo" />
                </div>

              </div>
              <div>
                <div className='text-2xl font-bold'>norvel</div>
              </div>
            </div>
          </div>
        )}

      <div className="flex flex-col items-center h-full py-4">
        <div className="flex flex-col gap-6">
          <div className='flex gap-2 items-center px-8 '>
            <div className="text-green-500 text-xl cursor-pointer">
              <DashboardSVG />
            </div>
            <div className='text-[12px] text-gray-600 pt-sans-bold'>Dashboard</div>
          </div>

          <div className={`${location.pathname === "/dashboard" ? 'bg-green-400 py-2.5' : ''}`}>
            <div className='flex gap-2 items-center px-8 cursor-pointer' onClick={handleIconBack}>
              <div className="text-gray-500 text-xl">
                {location.pathname === "/dashboard" ? <ClientsSVG /> : <ClientsSVG />}
              </div>
              <div className='text-[12px] text-gray-600 pt-sans-bold'>Clients</div>
            </div>
          </div>

          {menus && menus.consultant && (
            <div className={`${location.pathname === "/university" ? 'bg-green-400 py-2' : ''}`}>
              <div className='flex gap-2 items-center px-8 cursor-pointer' onClick={handleCourse}>
                <div className="text-gray-500 text-xl">
                  {location.pathname === "/university" ? <CourseSVG /> : <CourseSVG />}
                </div>
                <div className='text-[12px] text-gray-600 pt-sans-bold'>Course</div>
              </div>
            </div>
          )}

          {menus && menus.add_staff && (
            <div className={`${location.pathname === "/UniversityAgent" ? 'bg-green-400 py-2' : ''}`}>
              <div className='flex gap-2 items-center px-8 cursor-pointer' onClick={handleAgent}>
                <div className="text-gray-500 text-xl">
                  {location.pathname === "/university" ? <CourseagentSVG /> : <CourseagentSVG />}
                </div>
                <div className='text-[12px] text-gray-600 pt-sans-bold'>Course</div>
              </div>
            </div>
          )}

          {menus && menus.consultant && (
            <div className={`${location.pathname === "/countrydashboard" ? 'bg-green-400 py-2' : ''}`}>
              <div className='flex gap-2 items-center cursor-pointer px-8' onClick={handleCountry}>
                <div className="text-gray-500 text-xl cursor-pointer">
                  {location.pathname === "/countrydashboard" ? <CountrySVG /> : <CountrySVG />}
                </div>
                <div className='text-[12px] text-gray-600 pt-sans-bold'>Country</div>
              </div>
            </div>
          )}

          {menus && menus.consultant && (
            <div className={`${location.pathname === "/userside" ? 'bg-green-400 py-2' : ''}`}>
              <div className='flex gap-2 items-center cursor-pointer px-8' onClick={handleIconClick}>
                <div className="text-gray-500 text-xl">
                  {location.pathname === "/userside" ? <SettingsSVG fill="blue" /> : <SettingsSVG fill="blue" />}
                </div>
                <div className='text-[12px] text-gray-600 pt-sans-bold'>Settings</div>
              </div>
            </div>
          )}

          {menus && menus.add_staff && (
            <div className={`${location.pathname === "/agentstaffside" ? 'bg-green-400 py-2' : ''}`} onClick={handleAgentStaff}>
              <div className='flex gap-2 px-8 items-center cursor-pointer'>
                <div className="text-gray-500 text-xl ">
                  {(location.pathname === "/agentstaffside") || (location.pathname === "/sourceDashboard")
                    ? <SettingsSVG fill="blue" />
                    : <SettingsSVG fill="blue" />}
                </div>
                <div className='text-[12px] text-gray-600 pt-sans-bold'>Settings</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SideBar;





import React, { useState } from 'react';
import { motion } from 'framer-motion';
import axiosInstance from '../axiosInstance/axiosInstance';

const SourceAddForm = ({ showSource, setShowSource,fetchSources }) => {
  const [sourceName, setSourceName] = useState('');

  const handleClose = () => {
    setShowSource(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
        const response = await axiosInstance.post('/kanban/api/v1/source_list_create/', {
            name: sourceName,
        });
        if (response.status === 201) {
            console.log('Response:', response.data);
            fetchSources();
            setSourceName('');
            handleClose();
        } else {
            console.error('Error: Status code is not 200', response);
        }
    } catch (error) {
        console.error('Error adding source:', error);
    }
};


  return (
    <div>
      {showSource && (
        <>
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-70"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
          <motion.div
            className="p-6 z-10 fixed inset-0 flex justify-center items-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="w-[500px] bg-white shadow-md">
              <div className="bg-black flex items-center justify-center">
                <span className="text-2xl font-bold px-6 py-4 text-white">
                  Add Source
                </span>
              </div>
              <form className="px-6 pt-6 pb-8">
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-[12px] text-sm font-bold mb-2"
                    htmlFor="sourceName"
                  >
                    Add Source Here
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight text-[12px] focus:outline-none focus:shadow-outline"
                    id="sourceName"
                    type="text"
                    placeholder="Enter Source"
                    value={sourceName}
                    onChange={(e) => setSourceName(e.target.value)}
                  />
                </div>
                <div className="flex justify-end pt-10 gap-2">
                  <button
                    className="bg-black  pt-sans-regular text-[14px] text-white font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                  <button
                    className="bg-black pt-sans-regular text-[14px]  text-white font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </motion.div>
        </>
      )}
    </div>
  );
};

export default SourceAddForm;


import React from 'react'
import { useNavigate } from 'react-router-dom';
import Header from '../Header/Header'
import SideBar from '../SideBar/SideBar'
import CountryListingPage from './CountryListingPage'
import UniversityHeader from '../universityHeader/UniversityHeader'

const CountryDashboard = () => {
    const navigate = useNavigate();

    const handleUniversityClick = () => {
        navigate('/university'); // Navigate to /university
    };

    return (





        <div>
            <div className='relative'>

                <hr />
                <div className='flex pt- shadow-lg'>
                    <div className='pt-10 bg-white h-full sticky top-0 '>
                        <SideBar />
                    </div>
                    <div className='flex-grow bg-gray-50'>
                        <div className=' flex gap-4 py-6  px-4'>
                           
                            <button
                                className="bg-black text-white text-[12px] px-4 py-2 rounded pt-sans-bold"
                                onClick={handleUniversityClick} 
                            >
                                Universities
                            </button>


                            <div>
                                <button className="border border-1 border-black text-[12px]  text-black px-4 py-2 rounded pt-sans-bold ">
                                    Associates
                                </button>


                            </div>
                        </div>

                        <div className='flex justify-center '>
                            <CountryListingPage />
                        </div>
                    </div>
                </div>
            </div>





        </div>









    )
}

export default CountryDashboard